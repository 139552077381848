module.exports = {
  // eslint-disable-next-line no-loss-of-precision
  MaxMintCount: 300,
  BurnCareAmout: 1,
  mintCount: 1,
  mintCost: 1,
  totalGas: 400000,
  NFTCONTRACT_ADDRESS: '0x4c75f56dD9745c2DA1f6033595B4D5998F6e535e',
  TOKENCONTRACT_ADDRESS: '0x0D36197eCFd7332E9E006F5c89b5b4b69091CDff',
}
